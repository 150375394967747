export type SelectedFacet = {
    facetName: string;
    facetValue: string;
};

export type SearchFacet = {
    name: string;
    displayName: string;
    facetType: string;
    values: SearchFacetValue[];
    placeholderText: string;
    isRequired: boolean;
};

export type SearchFacetValue = {
    name: string;
    displayName: string;
    count: number;
};

export enum FilterType {
    CATEGORIES = "Category",
}

export type FilterText = Record<FilterType, string>;

export type FilterState = Record<FilterType, FilterAppliedState>;

export type FilterAppliedState = {
    value: string[];
};

export type SearchApiParameters = {
    queryText: string;
    limit: number;
    skip: number;
    siteId?: string;
    facets?: SelectedFacet[] | undefined;
    sortBy?: string;
    context?: string;
};

export const baseSearchApiParameters: SearchApiParameters = {
    queryText: "",
    limit: 10,
    skip: 0,
    siteId: "",
    facets: [],
    sortBy: "Relevance",
    context: "",
};

export type SearchApiResponseData = {
    results: SearchResultItem[];
    totalResults: number;
    facets: SearchFacet[];
};

export type SearchResultItem = {
    title: string;
    description: string;
    url: string;
    categories: SearchCategory[];
    keywords: string[];
    siteId: string;
    publishDate: string;
    image: SearchImage;
    score: number;
    restrictedContent: boolean;
    pageType: string;
    forceAccessIndicator: boolean;
    accessIndicatorTextOverride: string;
};

export type SearchImage = {
    id?: number;
    url?: string;
    guid?: string;
};

export type SearchCategory = {
    id: number;
    displayName: string;
};

export type AutoCompleteApiParameters = {
    term: string;
};

export const baseAutoCompleteApiParameters: AutoCompleteApiParameters = {
    term: "",
};

export type AutoCompleteApiResponseData = string[];

export type SiteIdData = {
    key: string;
    value: string;
};

export type SiteIdApiResponseData = SiteIdData[];
