export interface SearchAppBaseText {
    filtersLabelText : string;
    resetFiltersLabelText : string;
    sortByMostRelevantText : string;
    sortByDateNewestText : string;
    sortByDateOldestText : string;
    sortByTitleAToZText : string;
    sortByTitleZToAText : string;
    searchButtonText : string;
    searchFieldPlaceholderText : string;
    popularSearchesText : string;
    noResultsText : string;
    accessIndicatorText: string;
    dateErrorMessage: string;
    requiredText: string;
}

export const defaultBaseText : SearchAppBaseText = {
    filtersLabelText : "Filters",
    resetFiltersLabelText : "Reset filters",
    sortByMostRelevantText : "Most relevant",
    sortByDateNewestText : "Date (newest)",
    sortByDateOldestText : "Date (oldest)",
    sortByTitleAToZText : "Title (A-Z)",
    sortByTitleZToAText : "Title (Z-A)",
    searchButtonText : "Search",
    searchFieldPlaceholderText : "Search...",
    popularSearchesText : "Popular searches",
    noResultsText: "No results found",
    accessIndicatorText: "Premium Content",
    dateErrorMessage: "End Date must be later than Start Date",
    requiredText: "*Required"
}